export const CompanyOutlineData = {
  name: "株式会社宮城運輸",
  representative: "高田 登志江 ",
  address: "宮城県名取市高舘熊野堂字岩口下46-1",
  telephone: "(022) 386-3111",
  fax: "(022) 386-6521",
  establishDate: "昭和50年8月6日",
  capital: "3000万円",
  nEmployee: "207人（令和6年8月15日現在、関係会社含む、派遣社員除く)",
  bank: ["三井住友銀行仙台支店", "南工中金仙台支店", "七十七銀行西多賀支店"],
  business: [
    "一般貨物自動車運送事業(特別積み合せ貨物自動車運送事業を含む)",
    "貨物利用運送事業",
    "倉庫業（常温、冷蔵、冷凍）",
  ],
};
